@font-face {
  font-family: Poppins;
  font-weight: 100;
  src: url("Poppins-Thin.6aabb04f.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 100;
  src: url("Poppins-ThinItalic.bb71d30e.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 200;
  src: url("Poppins-ExtraLight.08072e1e.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 200;
  src: url("Poppins-ExtraLightItalic.1ab77018.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("Poppins-Light.6e6906a1.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  src: url("Poppins-LightItalic.580c8e9b.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.fd8c1383.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  src: url("Poppins-Italic.aa2aac16.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("Poppins-Medium.7f5202f0.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 500;
  src: url("Poppins-MediumItalic.66b99725.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("Poppins-SemiBold.2d1f49c6.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 600;
  src: url("Poppins-SemiBoldItalic.2f09c583.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("Poppins-Bold.ef368a79.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
  src: url("Poppins-BoldItalic.5bc45a2f.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 800;
  src: url("Poppins-ExtraBold.6617944e.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 800;
  src: url("Poppins-ExtraBoldItalic.c47e3583.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 900;
  src: url("Poppins-Black.8b8a2cb6.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
  src: url("Poppins-BlackItalic.6c665a99.ttf") format("truetype");
}

html, body, #coachmate-web {
  height: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.coachmate-input--password {
  font: small-caption;
  font-size: 16px;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.-left-\[10px\] {
  left: -10px;
}

.-right-\[10px\] {
  right: -10px;
}

.-left-72 {
  left: -18rem;
}

.left-0 {
  left: 0;
}

.left-72 {
  left: 18rem;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-14 {
  top: 3.5rem;
}

.-right-2 {
  right: -.5rem;
}

.right-3 {
  right: .75rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-2 {
  top: .5rem;
}

.top-1\/2 {
  top: 50%;
}

.left-1\/2 {
  left: 50%;
}

.-top-2 {
  top: -.5rem;
}

.top-0\.5 {
  top: .125rem;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.right-6 {
  right: 1.5rem;
}

.top-16 {
  top: 4rem;
}

.z-1 {
  z-index: 1;
}

.z-dropdown {
  z-index: 1100;
}

.z-header {
  z-index: 1000;
}

.z-page {
  z-index: 100;
}

.z-modal {
  z-index: 1300;
}

.z-toast {
  z-index: 1400;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-0 {
  height: 0;
}

.h-3 {
  height: .75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[calc\(100\%-80px\)\] {
  height: calc(100% - 80px);
}

.h-20 {
  height: 5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-12 {
  height: 3rem;
}

.h-9 {
  height: 2.25rem;
}

.h-11 {
  height: 2.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-5 {
  height: 1.25rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-7 {
  height: 1.75rem;
}

.h-0\.5 {
  height: .125rem;
}

.w-full {
  width: 100%;
}

.w-0 {
  width: 0;
}

.w-3 {
  width: .75rem;
}

.w-8 {
  width: 2rem;
}

.w-72 {
  width: 18rem;
}

.w-20 {
  width: 5rem;
}

.w-12 {
  width: 3rem;
}

.w-6 {
  width: 1.5rem;
}

.w-9 {
  width: 2.25rem;
}

.w-11 {
  width: 2.75rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-5 {
  width: 1.25rem;
}

.w-4 {
  width: 1rem;
}

.w-auto {
  width: auto;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/2 {
  width: 50%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-4xs {
  max-width: 8rem;
}

.max-w-3xs {
  max-width: 12rem;
}

.max-w-2xs {
  max-width: 16rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-bounce {
  animation: 1s infinite bounce;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-ui-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-x-\[10px\] {
  border-left-width: 10px;
  border-right-width: 10px;
}

.border-b-\[10px\] {
  border-bottom-width: 10px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-solid {
  border-style: solid;
}

.border-ui-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(109 204 232 / var(--tw-border-opacity));
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: rgb(235 87 87 / var(--tw-border-opacity));
}

.border-ui-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-ui-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-ui-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-success {
  --tw-border-opacity: 1;
  border-color: rgb(39 174 96 / var(--tw-border-opacity));
}

.border-x-transparent {
  border-left-color: #0000;
  border-right-color: #0000;
}

.border-b-ui-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-b-primary-800 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(23 121 150 / var(--tw-border-opacity));
}

.border-opacity-40 {
  --tw-border-opacity: .4;
}

.bg-ui-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-ui-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-primary-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 121 150 / var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 204 232 / var(--tw-bg-opacity));
}

.bg-ui-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-ui-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(39 174 96 / var(--tw-bg-opacity));
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(235 87 87 / var(--tw-bg-opacity));
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-info {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-ui-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 156 194 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#201E32\] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 30 50 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-to: #0000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-ui-900 {
  --tw-gradient-to: #111827;
}

.p-2 {
  padding: .5rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-8 {
  padding: 2rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-0\.5 {
  padding: .125rem;
}

.p-0 {
  padding: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(145 216 238 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(12 60 75 / var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(109 204 232 / var(--tw-text-opacity));
}

.text-danger {
  --tw-text-opacity: 1;
  color: rgb(235 87 87 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(39 174 96 / var(--tw-text-opacity));
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-ui-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-white\/90 {
  color: #ffffffe6;
}

.text-opacity-90 {
  --tw-text-opacity: .9;
}

.text-opacity-60 {
  --tw-text-opacity: .6;
}

.text-opacity-40 {
  --tw-text-opacity: .4;
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-ui-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(75 85 99 / var(--tw-placeholder-opacity));
}

.opacity-50 {
  opacity: .5;
}

.opacity-80 {
  opacity: .8;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.opacity-60 {
  opacity: .6;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.first\:rounded-t-md:first-child {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.first\:pl-0:first-child {
  padding-left: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:rounded-b-md:last-child {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.last\:pr-0:last-child {
  padding-right: 0;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:border-primary-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(109 204 232 / var(--tw-border-opacity));
}

.focus\:border-danger:focus {
  --tw-border-opacity: 1;
  border-color: rgb(235 87 87 / var(--tw-border-opacity));
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (min-width: 640px) {
  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-\[450px\] {
    width: 450px;
  }

  .sm\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:transition-none {
    transition-property: none;
  }
}





/*# sourceMappingURL=index.5fe5460f.css.map */
