html,
body,
#coachmate-web {
  height: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
}

.coachmate-input--password {
  font: small-caption;
  font-size: 16px;
}